import React, { useState } from 'react'

import { useExitIntent } from 'use-exit-intent'
import { H1, H2, H5, Paragraph } from '~/styled/components/typography'
import {
  Dialog,
  DialogTitle,
  DialogOverlay,
  DialogClose,
  DialogContent,
  DialogTitleNumber,
} from './styles'
import { RiCloseFill } from '@remixicon/react'
import { Button } from '~/components/button'
import { useCartContext } from '~/context/cart-context'
import { string } from 'yup'
import { start } from 'repl'
import { set } from 'store'
import { useCustomerContext } from '~/context/customer-context'
import { useExpireDate } from './hooks/useExpireDate'
import { MOCK } from './mock'

const numberToUnicodeMap = {
  '0': '\u0030',
  '1': '\u0031',
  '2': '\u0032',
  '3': '\u0033',
  '4': '\u0034',
  '5': '\u0035',
  '6': '\u0036',
  '7': '\u0037',
  '8': '\u0038',
  '9': '\u0039',
}

// Função para substituir números por seus caracteres Unicode
function replaceNumbersWithUnicode(str: string) {
  return str.replace(/\d/g, match => numberToUnicodeMap[match as keyof typeof numberToUnicodeMap])
}

const ExitIntentPopup = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { totalPrice, totalQuantity, updateCheckoutAttrs, checkout } = useCartContext()
  const { isTrade } = useCustomerContext()

  const { setEndDate, formatted } = useExpireDate()

  const { registerHandler, resetState, unsubscribe } = useExitIntent({
    cookie: {
      key: 'exit-intent-discount',
      daysToExpire: 7,
    },
  })

  registerHandler({
    id: 'exit-intent',
    handler: () => {
      if (totalQuantity >= MOCK.validationValue && !isTrade) {
        setEndDate(new Date(Date.now() + MOCK.expires * 60 * 1000))
        fetch('https://ben-soleimani-apps-checkout-api.vercel.app/api/exit-intent-discount', {
          method: 'POST',
          body: JSON.stringify({
            checkoutId: checkout?.id,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        setIsOpen(true)
        unsubscribe()
      } else {
        resetState()
      }
    },
    context: ['onDesktop'],
  })

  const close = () => {
    setIsOpen(false)
  }

  const formattedSavings = (
    parseFloat(totalPrice) *
    (MOCK.discountValue / 100)
  ).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })

  if (!isOpen) {
    return null
  }

  return (
    <DialogOverlay>
      <Dialog>
        <DialogTitle style={{ color: '#808080' }}>
          BEFORE <i>You</i> GO
        </DialogTitle>

        <DialogClose onClick={close}>
          <RiCloseFill />
        </DialogClose>

        <DialogContent>
          <Paragraph
            style={{
              letterSpacing: '1px',
            }}
          >
            ENJOY
          </Paragraph>

          <DialogTitleNumber>
            <H1
              style={{
                marginBottom: 0,
              }}
            >
              {MOCK.discountValue}
            </H1>
            <H2
              style={{
                marginBottom: 0,
              }}
            >
              % <i>Off</i>
            </H2>
          </DialogTitleNumber>

          <Paragraph>DISCOUNT APPLIED AT CHECKOUT</Paragraph>

          <Paragraph
            style={{
              fontWeight: 500,
            }}
          >
            ESTIMATED SAVINGS: ${formattedSavings}
          </Paragraph>

          <H5 style={{ color: '#808080', margin: '30px 0' }}>EXPIRES IN {formatted}</H5>

          <Button
            sxProps={{
              marginBottom: 10,
            }}
            as="link"
            to={checkout?.webUrl as string}
            label="REDEEM NOW"
          />
        </DialogContent>
      </Dialog>
    </DialogOverlay>
  )
}

export default ExitIntentPopup
