import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Attribute } from 'shopify-storefront-api-typings'
import { graphql, useStaticQuery } from 'gatsby'
import {
  CheckoutLineItem,
  CheckoutLineItemInput,
  CheckoutProductVariant,
  CheckoutType,
  MailingAddressInput,
} from '~/@types/models'
import useShopifyClient from '../hooks/use-shopify-client'
import store from 'store'
import { countQuantity, mapCheckout } from '~/utils/checkout'
import { useCustomerContext } from '~/context/customer-context'
import { debounce } from 'throttle-debounce'
import AddToCartSnippet from '~/components/klaviyo/added-to-cart-snippet'
import UserTrackingSnippet from '~/components/klaviyo/logged-user-snippet'

type CheckoutItem = {
  item: CheckoutLineItemInput
  title: string
  variant: CheckoutProductVariant
  checkoutAttributes?: readonly Attribute[]
}
type CartContextType = {
  items: CheckoutLineItem[]
  totalQuantity: number
  totalPrice: string
  overlayOpen: boolean
  setOverlayOpen: (open: boolean) => void
  saveItem: (
    item: CheckoutLineItemInput,
    title: string,
    variant: CheckoutProductVariant,
    checkoutAttributes?: readonly Attribute[]
  ) => Promise<void>
  saveItems: (items: CheckoutItem[]) => Promise<void>
  applyDiscount: (discountCode: string) => Promise<void>
  removeDiscount: () => Promise<void>
  updateMailingAddress: (mailingAddressInput: MailingAddressInput) => Promise<CheckoutType>
  updateCheckoutEmail: (email: string) => Promise<CheckoutType>
  updateShippingLine: (lineHandle: string) => Promise<CheckoutType>
  updateCheckoutAttrs: (attrs: any) => Promise<CheckoutType>
  resetCheckout: () => Promise<void>
  fetchCheckout: () => Promise<void>
  checkout: CheckoutType | null
  isInit?: boolean
  isFetching: boolean
}

const itemsSum = (items: CheckoutLineItem[]) => {
  const intialValue = 0
  return items.reduce(
    (sum, obj: CheckoutLineItem) => sum + obj.quantity * parseFloat(obj.variant.price.amount),
    intialValue
  )
}

const CartContext = createContext<CartContextType>({
  items: [],
  totalQuantity: 0,
  totalPrice: '',
  overlayOpen: false,
  setOverlayOpen: (open: boolean) => undefined,
  saveItem: async (item: CheckoutLineItemInput) => undefined,
  applyDiscount: async (discountCode: string) => undefined,
  removeDiscount: async () => undefined,
  updateMailingAddress: async (mailingAddressInput: MailingAddressInput) => undefined,
  updateCheckoutEmail: async (email: string) => undefined,
  updateShippingLine: async (lineHandle: string) => undefined,
  resetCheckout: async () => undefined,
  fetchCheckout: async () => undefined,
  checkout: null,
  isFetching: false,
})

const CartProvider: FC = ({ children }) => {
  const [items, setItems] = useState<CheckoutLineItem[]>([])
  const [localPrice, setLocalPrice] = useState<string>('0')
  const [checkout, setCheckout] = useState<CheckoutType | null>(null)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [overlayOpen, setOverlayOpen] = useState(false)
  const { client } = useShopifyClient()
  const { customer, auth, fetchCustomer } = useCustomerContext()
  const [mostRecentItem, setMostRecentItem] = useState({})
  const [tracking, setTracking] = useState(false)
  const [newItemAdded, setNewItemAdded] = useState(false)
  const lastUpdate = useRef<number>()
  const [isInit, setIsInit] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const storeCheckout = (
    response: CheckoutType | null,
    { updateItems = true, maintainOrder = false } = {}
  ) => {
    if (response) {
      const updatedCheckout = mapCheckout(response)
      setCheckout(updatedCheckout)
      store.set('cart', updatedCheckout)
      if (updateItems) {
        if (!maintainOrder) {
          setItems(items => {
            // updatedCheckout.lineItems base on id of items in state
            const updatedItems = items
              ?.map(item => {
                const existingItem = updatedCheckout.lineItems.find(
                  i => i.variant.id === item.variant.id
                )
                return existingItem as CheckoutLineItem
              })
              .filter(Boolean)

            updatedCheckout.lineItems.forEach(o => {
              const existingItem = updatedItems.find(i => i.variant.id === o.variant.id)
              if (!existingItem) {
                updatedItems.push(o)
              }
            })

            return updatedItems
          })
        } else {
          setItems(updatedCheckout.lineItems)
        }
      }
      setTotalQuantity(countQuantity(updatedCheckout.lineItems))
      setLocalPrice(updatedCheckout.totalPrice.amount)
    } else {
      setCheckout(null)
      store.remove('cart')
      setItems([])
      setTotalQuantity(0)
      setLocalPrice('0')
    }
  }

  const saveCheckoutToCustomer = async (checkoutId: string) => {
    const newCheckout = await client.checkout().getCheckout(checkoutId)
    if (newCheckout && newCheckout.completedAt) {
      // Checkout already completed
      storeCheckout(null)
    } else {
      const response = await client
        .checkout()
        .associateCustomer(checkoutId, auth?.accessToken ?? ``)
      storeCheckout(response)
      await fetchCustomer()

      setTimeout(() => {
        setIsFetching(false)
      }, 1000)
    }
  }

  useEffect(() => {
    // Loading cart state on user change
    const getUserCheckout = async (checkoutId: string) => {
      const newCheckout = await client.checkout().getCheckout(checkoutId)
      storeCheckout(newCheckout)
    }

    if (customer) {
      if (
        !checkout ||
        !customer.lastIncompleteCheckout ||
        (checkout &&
          customer.lastIncompleteCheckout &&
          customer.lastIncompleteCheckout.id !== checkout.id)
      ) {
        if (
          (!checkout && customer.lastIncompleteCheckout) ||
          (customer.lastIncompleteCheckout &&
            customer.lastIncompleteCheckout.createdAt > checkout.createdAt)
        ) {
          // Fetch cart from user
          getUserCheckout(customer.lastIncompleteCheckout.id)
        } else if (checkout && auth) {
          // Write checkout to user
          saveCheckoutToCustomer(checkout.id)
        }
      }
    }
  }, [checkout])

  const resetCheckout = useCallback(async () => {
    const newCheckout = await client.checkout().createCheckout({
      lineItems: [],
    })

    storeCheckout(newCheckout)
  }, [checkout])

  useEffect(() => {
    if (!isInit) {
      return
    }
    if ((checkout && !checkout?.lineItems?.length && !items.length) || !items.length) {
      setCheckout(null)
      store.remove('cart')
      setTotalQuantity(0)
      setLocalPrice('0')
    }
  }, [checkout, items, isInit])

  useEffect(() => {
    if (isInit) {
      return
    }

    const initializeFetchCheckout = async (checkout: CheckoutType) => {
      if (!checkout?.id) {
        storeCheckout(null)
        setIsInit(true)
        return
      }

      const checkCheckout = await client.checkout().getCheckout(checkout.id)

      if (!checkCheckout) {
        const restoreItems = checkout.lineItems.map(item => {
          return {
            variantId: item.variant.id,
            quantity: item.quantity,
            customAttributes: item.customAttributes,
          } as CheckoutLineItemInput
        })

        const response = await client.checkout().createCheckout({
          lineItems: restoreItems,
        })

        if (response) {
          storeCheckout(response)
        } else {
          storeCheckout(null)
        }

        setIsInit(true)

        return
      }

      if (checkCheckout.completedAt) {
        storeCheckout(null)
      } else {
        storeCheckout(checkCheckout)
      }

      setIsInit(true)
    }

    const getCustomer = async () => {
      await fetchCustomer()
    }

    if (auth) {
      getCustomer()
    }

    const loadedCart = store.get('cart')
    if (loadedCart) {
      initializeFetchCheckout(loadedCart)
    }
  }, [isInit])

  const createNewCheckout = useCallback(
    async (data: CheckoutLineItemInput[], checkoutAttributes = {}, lastUpdateTime = 0) => {
      const response = await client.checkout().createCheckout({
        lineItems: data,
        ...checkoutAttributes,
      })
      storeCheckout(response, {
        updateItems: lastUpdate.current === lastUpdateTime || lastUpdateTime === 0,
      })

      if (customer) {
        await saveCheckoutToCustomer(response.id)
      }

      setTimeout(() => {
        setIsFetching(false)
      }, 1000)

      return response
    },
    [customer]
  )

  const updateDebounce = useCallback(
    debounce(
      1000,
      false,
      (
        checkoutId: string,
        items: CheckoutLineItem[],
        checkoutAttributes = null,
        lastUpdateTime = 0
      ) => {
        let countErrors = 0
        const updateAsync = async () => {
          if (lastUpdateTime > 0 && lastUpdate.current !== lastUpdateTime) {
            return
          }

          const inputItems = items.map(item => {
            return {
              variantId: item.variant.id,
              quantity: item.quantity,
              customAttributes: item.customAttributes,
            } as CheckoutLineItemInput
          })

          if (checkoutAttributes) {
            try {
              await client.checkout().updateCheckout(checkoutId, checkoutAttributes)
            } catch (e) {
              // todo - log into Sentry
            }
          }

          const response = await client.checkout().replaceItems(checkoutId, inputItems)

          if (!response) {
            countErrors++
            if (countErrors <= 3) {
              setTimeout(() => {
                updateAsync()
              }, 1000 * countErrors)
            }
            return
          }

          if (lastUpdateTime === 0 || lastUpdate.current === lastUpdateTime) {
            storeCheckout(response)
          }
        }

        updateAsync().then(() => {
          setTimeout(() => {
            setIsFetching(false)
          }, 1000)
        })
      }
    ),
    [totalQuantity]
  )

  const updateMailingAddress = async (shippingAddress: MailingAddressInput) => {
    const response = await client.client
      .checkout()
      .updateShippingAddress(checkout.id, shippingAddress)
    storeCheckout(response)
    return response
  }

  const updateCheckoutEmail = async (email: string) => {
    const response = await client.checkout().updateCheckoutEmail(checkout.id, email)
    storeCheckout(response)
    return response
  }

  const updateShippingLine = async (lineHandle: string) => {
    const response = await client.checkout().updateShippingLine(checkout.id, lineHandle)
    storeCheckout(response)
    return response
  }

  const updateCheckoutAttrs = async (attrs: any) => {
    const response = await client.checkout().updateCheckout(checkout.id, attrs)
    storeCheckout(response)
    return response
  }

  const base64Encode = (data: string) => btoa(data)

  const saveItem = useCallback(
    async (
      data: CheckoutLineItemInput,
      title: string,
      variant: CheckoutProductVariant,
      checkoutAttributes?: any
    ) => {
      setIsFetching(true)
      setMostRecentItem({
        id: data.variantId,
        quantity: data.quantity,
        title: title,
        variant: {
          ...variant,
          shopifyId: base64Encode(variant.id),
        },
        customAttributes: data.customAttributes,
      })

      setNewItemAdded(true)
      if (customer) {
        setTracking(true)
      }

      if (items.length === 0 && !checkout) {
        // Update state locally
        setItems([
          {
            id: data.variantId,
            quantity: data.quantity,
            title: title,
            variant: {
              ...variant,
              id: variant.shopifyId,
              shopifyId: base64Encode(variant.id),
            },
            customAttributes: data.customAttributes,
          },
        ])
        setLocalPrice((data.quantity * variant.price.amount).toString())
        setTotalQuantity(data.quantity)
        // Create new checkout
        lastUpdate.current = Date.now()
        await createNewCheckout([data], checkoutAttributes, lastUpdate.current)
      } else if (checkout) {
        // Update existing checkout
        const itemCheck =
          items.length > 0
            ? items.find((i: CheckoutLineItem) => i.variant.id === data.variantId)
            : null
        let totalPrice = itemsSum(items)
        if (itemCheck) {
          // Update existing item
          itemCheck.quantity += data.quantity
          itemCheck.customAttributes = [
            ...(itemCheck.customAttributes || []),
            ...(data.customAttributes || []),
          ]
          totalPrice += data.quantity * parseFloat(variant.price.amount)
          setLocalPrice(totalPrice)
          if (itemCheck.quantity > 0) {
            setItems([...items])
            setTotalQuantity(countQuantity(items))
            lastUpdate.current = Date.now()
            updateDebounce(checkout.id, items, checkoutAttributes, lastUpdate.current)
          } else {
            // Delete item
            const newItems = items
            const delIndex = items.indexOf(itemCheck)
            newItems.splice(delIndex, 1)
            setItems([...newItems])
            setTotalQuantity(countQuantity(newItems))
            lastUpdate.current = Date.now()
            updateDebounce(checkout.id, newItems, checkoutAttributes, lastUpdate.current)
          }
        } else {
          // Add new item
          const newItems = items

          const newItem = {
            id: data.variantId,
            quantity: data.quantity,
            title: title,
            variant: {
              ...variant,
              id: variant.shopifyId,
              shopifyId: base64Encode(variant.id), // todo - base64
            }, // We need to replace ProductVariant id with shopify checkout ID
            customAttributes: data.customAttributes,
          }

          newItems.push(newItem)
          setItems([...newItems])
          setTotalQuantity(countQuantity(newItems))
          totalPrice += data.quantity * parseFloat(variant.price.amount)
          setLocalPrice(totalPrice)
          lastUpdate.current = Date.now()
          updateDebounce(checkout.id, newItems, checkoutAttributes, lastUpdate.current)
        }
      }
    },
    [checkout, createNewCheckout, items, totalQuantity, updateMailingAddress]
  )

  const saveItems = useCallback(
    async (data: CheckoutLineItemInput[], checkoutAttributes?: any) => {
      const itemsToCheckout = []
      const newItems = data.map(d => {
        itemsToCheckout.push({
          ...d.product,
        })
        return {
          ...d.product,
          id: d.product.variantId,
          title: d.title,
          variant: {
            ...d.variant,
            id: d.variant.shopifyId,
            shopifyId: base64Encode(d.variant.id),
          },
        }
      })

      setMostRecentItem({
        id: data[0].product.variantId,
        quantity: data[0].product.quantity,
        title: data[0].title,
        variant: {
          ...data[0].variant,
          shopifyId: base64Encode(data[0].variant.id),
        },
        customAttributes: data[0].product.customAttributes,
      })

      setNewItemAdded(true)
      if (customer) {
        setTracking(true)
      }

      if (items.length === 0 && !checkout) {
        // Update state locally
        setItems(newItems)
        setLocalPrice(
          newItems
            .reduce((sum, item) => sum + item.quantity * parseFloat(item.variant.price.amount), 0)
            .toString()
        )
        setTotalQuantity(countQuantity(newItems))
        // Create new checkout
        lastUpdate.current = Date.now()
        await createNewCheckout(itemsToCheckout, checkoutAttributes, lastUpdate.current)
      } else if (checkout) {
        // Update existing checkout
        const existingItems = items
        const totalPrice = existingItems.reduce(
          (sum, item) => sum + item.quantity * parseFloat(item.variant.price.amount),
          0
        )
        const newItemsCount = newItems.reduce((sum, item) => sum + item.quantity, 0)
        const newTotalPrice = totalPrice + newItemsCount * parseFloat(data[0].variant.price.amount)
        const newItemsToCheckout = []

        newItems.forEach(item => {
          const checkItem = existingItems.find(i => i.variant.id === item.id)
          if (checkItem) {
            checkItem.quantity += item.quantity
          } else {
            newItemsToCheckout.push(item)
          }
        })
        const newItemsArray = [...existingItems, ...newItemsToCheckout]
        setItems(newItemsArray)
        setLocalPrice(newTotalPrice)
        setTotalQuantity(countQuantity(newItemsArray))
        lastUpdate.current = Date.now()
        updateDebounce(checkout.id, newItemsArray, checkoutAttributes, lastUpdate.current)
      }
    },
    [checkout, createNewCheckout, items, totalQuantity, updateMailingAddress]
  )

  const applyDiscount = useCallback(
    async (discountCode: string) => {
      if (checkout) {
        const response = await client.checkout().applyDiscount(checkout.id, discountCode)
        storeCheckout(response)
      }
    },
    [checkout]
  )

  const removeDiscount = useCallback(async () => {
    if (checkout) {
      const response = await client.checkout().removeDiscount(checkout.id)
      storeCheckout(response)
    }
  }, [checkout])

  const fetchCheckout = useCallback(async () => {
    const newCheckout = await client.checkout().getCheckout(checkout.id)
    storeCheckout(newCheckout)
  }, [checkout])

  return (
    <CartContext.Provider
      value={{
        items,
        totalQuantity,
        totalPrice: localPrice,
        saveItem,
        saveItems,
        checkout,
        applyDiscount,
        removeDiscount,
        overlayOpen,
        setOverlayOpen,
        updateMailingAddress,
        updateCheckoutEmail,
        updateShippingLine,
        resetCheckout,
        fetchCheckout,
        updateCheckoutAttrs,
        isInit,
        isFetching,
      }}
    >
      {newItemAdded && (
        <AddToCartSnippet
          checkout={checkout}
          items={items}
          recentItem={mostRecentItem}
          newItemBool={newItemAdded}
          setNewItemBool={setNewItemAdded}
        />
      )}
      {tracking && (
        <UserTrackingSnippet customer={customer} tracking={tracking} setTracking={setTracking} />
      )}
      {children}
    </CartContext.Provider>
  )
}

const useCartContext = () => {
  const context = useContext(CartContext)

  if (context === undefined) {
    throw new Error('useCustomerContext must be used within a CustomerProvider')
  }

  return context
}

export { CartProvider, useCartContext }

export default CartProvider
