export const MOCK: ApiDiscountTrigger = {
  validationType: 'quantity', // quantity | amount
  validationValue: 3,
  expires: 15, // minutes
  discountType: 'percentage', // percentage | fixed
  discountValue: 10,
  message: '10% off',
}

export interface ApiDiscountTrigger {
  validationType: 'quantity' | 'fixed'
  validationValue: number
  discountType: 'percentage' | 'fixed'
  discountValue: number
  message: string
  expires: number
}
