import React from 'react'
import { IUseSwymlists, useSwymlists } from '../hooks/useSwymlists'
import { graphql, useStaticQuery } from 'gatsby'
import { ProductItemType } from '~/@types/models'

interface QueryResult {
  allShopifyProduct: {
    edges: {
      node: ProductItemType
    }[]
  }
}

const transformQueryResult = (data: QueryResult) => {
  return data.allShopifyProduct.edges.map(edge => edge.node)
}

const DashboardContext = React.createContext<any>(null)
export const DashboardContentProvider = ({ children }: any) => {
  const {
    listsArray,
    createWishlists,
    getSwymlists,
    hasNoWishlists,
    hasNoSflWishlists,
    loading,
    sflLists,
    addToSfl,
    addToWishlist,
    defaultList,
    removeFromSfl,
    removeFromWishlist,
    regid,
    sessionid,
  } = useSwymlists()

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      allShopifyProduct {
        edges {
          node {
            id
            shopifyId
            handle
            title
            variants {
              id
              title
              price {
                amount
              }
              compareAtPrice {
                amount
                currencyCode
              }
              shopifyId
              shopifyId2
            }
          }
        }
      }
    }
  `)

  const products = transformQueryResult(query)

  return (
    <DashboardContext.Provider
      value={{
        listsArray,
        createWishlists,
        getSwymlists,
        hasNoWishlists,
        hasNoSflWishlists,
        loading,
        products,
        sflLists,
        addToSfl,
        addToWishlist,
        removeFromSfl,
        removeFromWishlist,
        defaultList,
        regid,
        sessionid,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

interface IDashboardProviderReturn extends IUseSwymlists {
  loading: boolean
}

export const useDashboard = () => React.useContext<IDashboardProviderReturn>(DashboardContext)
