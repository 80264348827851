import { gql } from 'graphql-request'

export const PRODUCT_PAGES = gql`
  query GetProducts($first: Int!, $after: String) {
    products(first: $first, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
      }
    }
  }
`

export const GET_VARIANT = gql`
  query GetVariants($variantIds: [ID!]!) {
    variants: nodes(ids: $variantIds) {
      ... on ProductVariant {
        id
        title
        image {
          url
          height
          width
        }
        product {
          handle
          id
        }
      }
    }
  }
`

/*
query Product($handle: String)  {
  product(handle: $handle) {
    title
    metafields(identifiers: {namespace: "custom", key: "ankleContent"}) {
      value
    }
    collections(first:100){
      edges{
        node{
          handle
        }
      }
    }
  }
}
*/
export const PRODUCT_BY_HANDLE = gql`
  query Product($handle: String) {
    product(handle: $handle) {
      title
      id
      productType
      variants(first: 50) {
        edges {
          node {
            id
            availableForSale
            currentlyNotInStock
            quantityAvailable
            requiresShipping
            sku
            title
            weight
            weightUnit
            image {
              id
              altText
              originalSrc: url
              srcLargeDesktop: url(transform: { maxWidth: 2048 })
              srcDesktopMedium: url(transform: { maxWidth: 1600 })
              srcDesktop: url(transform: { maxWidth: 1024 })
              srcMobile: url(transform: { maxWidth: 768 })
              srcMobileSmall: url(transform: { maxWidth: 475 })
              srcSmall: url(transform: { maxWidth: 240 })
              srcXSmall: url(transform: { maxWidth: 56 })
            }
            product {
              handle
            }
            price {
              amount
              currencyCode
            }
            availableForSale
            compareAtPrice {
              amount
              currencyCode
            }
            selectedOptions {
              name
              value
            }
            metafields(
              identifiers: [
                { namespace: "global", key: "title_tag" }
                { namespace: "custom_fields", key: "pile_height_filter" }
                { namespace: "custom_fields", key: "size_filter" }
                { namespace: "custom_fields", key: "color_filter" }
                { namespace: "custom_fields", key: "fiber_filter" }
                { namespace: "custom_fields", key: "shape_filter" }
                { namespace: "custom_fields", key: "pairs_well_with" }
                { namespace: "custom_fields", key: "product_specs_fabric_content" }
                { namespace: "custom_fields", key: "product_specs_pile_height" }
                { namespace: "custom_fields", key: "product_specs_sizes" }
                { namespace: "custom_fields", key: "product_specs_coo" }
                { namespace: "custom_fields", key: "product_specs_goodweave" }
                { namespace: "custom_fields", key: "color_group_products" }
                { namespace: "custom_fields", key: "product_video" }
                { namespace: "custom", key: "video_id" }
                { namespace: "custom_fields", key: "product_video_caption" }
                { namespace: "custom_fields", key: "dimensions" }
                { namespace: "custom_fields", key: "template_key" }
                { namespace: "custom_fields", key: "you_might_like" }
                { namespace: "custom_fields", key: "frequently_purchased_with" }
                { namespace: "custom_fields", key: "disable_order_swatch" }
                { namespace: "custom_fields", key: "product_preorder_message" }
                { namespace: "custom_fields", key: "installation_guide_link" }
                { namespace: "custom_fields", key: "artwork_image" }
                { namespace: "custom_fields", key: "zoom_on_off" }
                { namespace: "custom_fields", key: "color" }
                { namespace: "custom_fields", key: "product_swatches" }
                { namespace: "custom", key: "rug_colors" }
                { namespace: "custom", key: "rug_style" }
                { namespace: "custom", key: "rug_size" }
                { namespace: "custom", key: "rug_pile_height" }
                { namespace: "custom", key: "rug_design" }
                { namespace: "custom", key: "shape" }
                { namespace: "custom", key: "rug_fiber" }
                { namespace: "custom", key: "variant_selection" }
                { namespace: "custom", key: "antique_style" }
                { namespace: "custom", key: "antique_name" }
                { namespace: "custom", key: "antiques_rugs_color" }
                { namespace: "custom", key: "antiques_pdf_link" }
                { namespace: "custom", key: "filter_size" }
                { namespace: "custom", key: "exact_size" }
                { namespace: "custom", key: "origin" }
                { namespace: "mm-google-shopping", key: "age_group" }
                { namespace: "custom_fields", key: "color_group_products" }
                { namespace: "mm-google-shopping", key: "custom_product" }
                { namespace: "mm-google-shopping", key: "gender" }
                { namespace: "mm-google-shopping", key: "material" }
              ]
            ) {
              key
              value
              namespace
            }
          }
        }
      }
      images(first: 1) {
        edges {
          node {
            src
          }
        }
      }
      collections(first: 100) {
        edges {
          node {
            handle
          }
        }
      }
    }
  }
`

export const PRODUCT_BY_ID = gql`
  query ProductbyId($id: ID!) {
    product(id: $id) {
      title
      id
      productType
      variants(first: 50) {
        edges {
          node {
            id
            availableForSale
            currentlyNotInStock
            quantityAvailable
            requiresShipping
            sku
            title
            weight
            weightUnit
            image {
              id
              altText
              originalSrc: url
              srcLargeDesktop: url(transform: { maxWidth: 2048 })
              srcDesktopMedium: url(transform: { maxWidth: 1600 })
              srcDesktop: url(transform: { maxWidth: 1024 })
              srcMobile: url(transform: { maxWidth: 768 })
              srcMobileSmall: url(transform: { maxWidth: 475 })
              srcSmall: url(transform: { maxWidth: 240 })
              srcXSmall: url(transform: { maxWidth: 56 })
            }
            product {
              handle
            }
            price {
              amount
              currencyCode
            }
            availableForSale
            compareAtPrice {
              amount
              currencyCode
            }
            selectedOptions {
              name
              value
            }
            metafields(
              identifiers: [
                { namespace: "global", key: "title_tag" }
                { namespace: "custom_fields", key: "pile_height_filter" }
                { namespace: "custom_fields", key: "size_filter" }
                { namespace: "custom_fields", key: "color_filter" }
                { namespace: "custom_fields", key: "fiber_filter" }
                { namespace: "custom_fields", key: "shape_filter" }
                { namespace: "custom_fields", key: "pairs_well_with" }
                { namespace: "custom_fields", key: "product_specs_fabric_content" }
                { namespace: "custom_fields", key: "product_specs_pile_height" }
                { namespace: "custom_fields", key: "product_specs_sizes" }
                { namespace: "custom_fields", key: "product_specs_coo" }
                { namespace: "custom_fields", key: "product_specs_goodweave" }
                { namespace: "custom_fields", key: "color_group_products" }
                { namespace: "custom_fields", key: "product_video" }
                { namespace: "custom", key: "video_id" }
                { namespace: "custom_fields", key: "product_video_caption" }
                { namespace: "custom_fields", key: "dimensions" }
                { namespace: "custom_fields", key: "template_key" }
                { namespace: "custom_fields", key: "you_might_like" }
                { namespace: "custom_fields", key: "frequently_purchased_with" }
                { namespace: "custom_fields", key: "disable_order_swatch" }
                { namespace: "custom_fields", key: "product_preorder_message" }
                { namespace: "custom_fields", key: "installation_guide_link" }
                { namespace: "custom_fields", key: "artwork_image" }
                { namespace: "custom_fields", key: "zoom_on_off" }
                { namespace: "custom_fields", key: "color" }
                { namespace: "custom_fields", key: "product_swatches" }
                { namespace: "custom", key: "rug_colors" }
                { namespace: "custom", key: "rug_style" }
                { namespace: "custom", key: "rug_size" }
                { namespace: "custom", key: "rug_pile_height" }
                { namespace: "custom", key: "rug_design" }
                { namespace: "custom", key: "shape" }
                { namespace: "custom", key: "rug_fiber" }
                { namespace: "custom", key: "variant_selection" }
                { namespace: "custom", key: "antique_style" }
                { namespace: "custom", key: "antique_name" }
                { namespace: "custom", key: "antiques_rugs_color" }
                { namespace: "custom", key: "antiques_pdf_link" }
                { namespace: "custom", key: "filter_size" }
                { namespace: "custom", key: "exact_size" }
                { namespace: "custom", key: "origin" }
                { namespace: "mm-google-shopping", key: "age_group" }
                { namespace: "custom_fields", key: "color_group_products" }
                { namespace: "mm-google-shopping", key: "custom_product" }
                { namespace: "mm-google-shopping", key: "gender" }
                { namespace: "mm-google-shopping", key: "material" }
              ]
            ) {
              key
              value
              namespace
            }
          }
        }
      }
      images(first: 1) {
        edges {
          node {
            src
          }
        }
      }
      collections(first: 100) {
        edges {
          node {
            handle
          }
        }
      }
    }
  }
`

export const PRODUCT = gql`
  fragment metafield on Metafield {
    key
    value
    namespace
  }
  query GetProducts($first: Int!, $after: String) {
    products(first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          handle
          availableForSale
          description
          descriptionHtml
          onlineStoreUrl
          options {
            id
            name
            values
          }
          productType
          tags
          title
          totalInventory
          updatedAt
          vendor
          tags
          collections(first: 250) {
            edges {
              node {
                id
                title
                handle
              }
            }
          }
          seo {
            title
            description
          }
          metafieldsGlobal: metafields(
            identifiers: [
              { namespace: "global", key: "title_tag" }
              { namespace: "custom_fields", key: "product_swatches" }
              { namespace: "custom", key: "rug_design" }
              { namespace: "custom_fields", key: "pile_height_filter" }
              { namespace: "custom_fields", key: "size_filter" }
              { namespace: "custom_fields", key: "color_filter" }
              { namespace: "custom_fields", key: "fiber_filter" }
              { namespace: "custom_fields", key: "shape_filter" }
              { namespace: "custom_fields", key: "pairs_well_with" }
              { namespace: "custom_fields", key: "product_specs_fabric_content" }
              { namespace: "custom_fields", key: "product_specs_pile_height" }
              { namespace: "custom_fields", key: "product_specs_sizes" }
              { namespace: "custom_fields", key: "product_specs_coo" }
              { namespace: "custom_fields", key: "product_specs_goodweave" }
              { namespace: "custom_fields", key: "color_group_products" }
              { namespace: "custom_fields", key: "product_video" }
              { namespace: "custom_fields", key: "product_video_caption" }
              { namespace: "custom_fields", key: "dimensions" }
              { namespace: "custom_fields", key: "template_key" }
              { namespace: "custom_fields", key: "you_might_like" }
              { namespace: "custom_fields", key: "frequently_purchased_with" }
              { namespace: "custom_fields", key: "disable_order_swatch" }
              { namespace: "custom_fields", key: "product_preorder_message" }
              { namespace: "custom_fields", key: "installation_guide_link" }
              { namespace: "custom_fields", key: "artwork_image" }
              { namespace: "custom_fields", key: "zoom_on_off" }
              { namespace: "custom_fields", key: "color" }
              { namespace: "custom", key: "rug_colors" }
              { namespace: "custom", key: "rug_style" }
              { namespace: "custom", key: "rug_size" }
              { namespace: "custom", key: "rug_pile_height" }
              { namespace: "custom", key: "shape" }
              { namespace: "custom", key: "rug_fiber" }
              { namespace: "custom", key: "variant_selection" }
              { namespace: "custom", key: "antique_style" }
              { namespace: "custom", key: "antique_name" }
              { namespace: "custom", key: "antiques_rugs_color" }
              { namespace: "custom", key: "antiques_pdf_link" }
              { namespace: "custom", key: "filter_size" }
              { namespace: "custom", key: "exact_size" }
              { namespace: "custom", key: "exact_size" }
              { namespace: "custom", key: "card_collection_tag" }
              { namespace: "custom", key: "related_products_type" }
              { namespace: "custom", key: "origin" }
              { namespace: "mm-google-shopping", key: "age_group" }
              { namespace: "custom_fields", key: "color_group_products" }
              { namespace: "mm-google-shopping", key: "custom_product" }
              { namespace: "mm-google-shopping", key: "gender" }
              { namespace: "mm-google-shopping", key: "material" }
            ]
          ) {
            ...metafield
          }
          metafields(
            identifiers: [
              { namespace: "custom_fields", key: "product_swatches" }
              { namespace: "custom", key: "rug_design" }
              { namespace: "global", key: "title_tag" }
              { namespace: "custom_fields", key: "pile_height_filter" }
              { namespace: "custom_fields", key: "size_filter" }
              { namespace: "custom_fields", key: "color_filter" }
              { namespace: "custom_fields", key: "fiber_filter" }
              { namespace: "custom_fields", key: "shape_filter" }
              { namespace: "custom_fields", key: "pairs_well_with" }
              { namespace: "custom_fields", key: "product_specs_fabric_content" }
              { namespace: "custom_fields", key: "product_specs_pile_height" }
              { namespace: "custom_fields", key: "product_specs_sizes" }
              { namespace: "custom_fields", key: "product_specs_coo" }
              { namespace: "custom_fields", key: "product_specs_goodweave" }
              { namespace: "custom_fields", key: "color_group_products" }
              { namespace: "custom_fields", key: "product_video" }
              { namespace: "custom_fields", key: "product_video_caption" }
              { namespace: "custom_fields", key: "dimensions" }
              { namespace: "custom_fields", key: "template_key" }
              { namespace: "custom_fields", key: "you_might_like" }
              { namespace: "custom_fields", key: "frequently_purchased_with" }
              { namespace: "custom_fields", key: "disable_order_swatch" }
              { namespace: "custom_fields", key: "product_preorder_message" }
              { namespace: "custom_fields", key: "installation_guide_link" }
              { namespace: "custom_fields", key: "artwork_image" }
              { namespace: "custom_fields", key: "zoom_on_off" }
              { namespace: "custom_fields", key: "color" }
              { namespace: "custom", key: "rug_colors" }
              { namespace: "custom", key: "rug_fiber" }
              { namespace: "custom", key: "rug_style" }
              { namespace: "custom", key: "rug_size" }
              { namespace: "custom", key: "rug_pile_height" }
              { namespace: "custom", key: "shape" }
              { namespace: "custom", key: "antique_style" }
              { namespace: "custom", key: "antique_name" }
              { namespace: "custom", key: "antiques_rugs_color" }
              { namespace: "custom", key: "antiques_pdf_link" }
              { namespace: "custom", key: "filter_size" }
              { namespace: "custom", key: "exact_size" }
              { namespace: "custom", key: "card_collection_tag" }
              { namespace: "custom", key: "related_products_type" }
              { namespace: "custom", key: "origin" }
              { namespace: "custom", key: "variant_selection" }
              { namespace: "mm-google-shopping", key: "age_group" }
              { namespace: "custom_fields", key: "color_group_products" }
              { namespace: "mm-google-shopping", key: "custom_product" }
              { namespace: "mm-google-shopping", key: "gender" }
              { namespace: "mm-google-shopping", key: "material" }
            ]
          ) {
            ...metafield
          }
          images(first: 250) {
            edges {
              node {
                altText
                id
                originalSrc: url
                srcLargeDesktop: url(transform: { maxWidth: 3000 })
                srcDesktopMedium: url(transform: { maxWidth: 2344 })
                srcDesktop: url(transform: { maxWidth: 1500 })
                srcMobile: url(transform: { maxWidth: 1125 })
                srcMobileSmall: url(transform: { maxWidth: 696 })
                srcSmall: url(transform: { maxWidth: 352 })
                srcXSmall: url(transform: { maxWidth: 82 })
              }
            }
          }
          variants(first: 50) {
            edges {
              node {
                id
                availableForSale
                currentlyNotInStock
                quantityAvailable
                requiresShipping
                sku
                title
                weight
                weightUnit
                image {
                  id
                  altText
                  originalSrc: url
                  srcLargeDesktop: url(transform: { maxWidth: 2048 })
                  srcDesktopMedium: url(transform: { maxWidth: 1600 })
                  srcDesktop: url(transform: { maxWidth: 1024 })
                  srcMobile: url(transform: { maxWidth: 768 })
                  srcMobileSmall: url(transform: { maxWidth: 475 })
                  srcSmall: url(transform: { maxWidth: 240 })
                  srcXSmall: url(transform: { maxWidth: 56 })
                }
                product {
                  handle
                }
                price {
                  amount
                  currencyCode
                }
                availableForSale
                compareAtPrice {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                metafields(
                  identifiers: [
                    { namespace: "global", key: "title_tag" }
                    { namespace: "custom_fields", key: "pile_height_filter" }
                    { namespace: "custom_fields", key: "size_filter" }
                    { namespace: "custom_fields", key: "color_filter" }
                    { namespace: "custom_fields", key: "fiber_filter" }
                    { namespace: "custom_fields", key: "shape_filter" }
                    { namespace: "custom_fields", key: "pairs_well_with" }
                    { namespace: "custom_fields", key: "product_specs_fabric_content" }
                    { namespace: "custom_fields", key: "product_specs_pile_height" }
                    { namespace: "custom_fields", key: "product_specs_sizes" }
                    { namespace: "custom_fields", key: "product_specs_coo" }
                    { namespace: "custom_fields", key: "product_specs_goodweave" }
                    { namespace: "custom_fields", key: "color_group_products" }
                    { namespace: "custom_fields", key: "product_video" }
                    { namespace: "custom", key: "video_id" }
                    { namespace: "custom_fields", key: "product_video_caption" }
                    { namespace: "custom_fields", key: "dimensions" }
                    { namespace: "custom_fields", key: "template_key" }
                    { namespace: "custom_fields", key: "you_might_like" }
                    { namespace: "custom_fields", key: "frequently_purchased_with" }
                    { namespace: "custom_fields", key: "disable_order_swatch" }
                    { namespace: "custom_fields", key: "product_preorder_message" }
                    { namespace: "custom_fields", key: "installation_guide_link" }
                    { namespace: "custom_fields", key: "artwork_image" }
                    { namespace: "custom_fields", key: "zoom_on_off" }
                    { namespace: "custom_fields", key: "color" }
                    { namespace: "custom_fields", key: "product_swatches" }
                    { namespace: "custom", key: "rug_colors" }
                    { namespace: "custom", key: "rug_style" }
                    { namespace: "custom", key: "rug_size" }
                    { namespace: "custom", key: "rug_pile_height" }
                    { namespace: "custom", key: "rug_design" }
                    { namespace: "custom", key: "shape" }
                    { namespace: "custom", key: "rug_fiber" }
                    { namespace: "custom", key: "variant_selection" }
                    { namespace: "custom", key: "antique_style" }
                    { namespace: "custom", key: "antique_name" }
                    { namespace: "custom", key: "antiques_rugs_color" }
                    { namespace: "custom", key: "antiques_pdf_link" }
                    { namespace: "custom", key: "filter_size" }
                    { namespace: "custom", key: "related_products_type" }
                    { namespace: "custom", key: "exact_size" }
                    { namespace: "custom", key: "card_collection_tag" }
                    { namespace: "custom", key: "origin" }
                    { namespace: "mm-google-shopping", key: "age_group" }
                    { namespace: "custom_fields", key: "color_group_products" }
                    { namespace: "mm-google-shopping", key: "custom_product" }
                    { namespace: "mm-google-shopping", key: "gender" }
                    { namespace: "mm-google-shopping", key: "material" }
                  ]
                ) {
                  key
                  value
                  namespace
                }
              }
            }
          }
        }
      }
    }
  }
`
