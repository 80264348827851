// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-pages-about-ben-tsx": () => import("./../../../src/pages/pages/about-ben.tsx" /* webpackChunkName: "component---src-pages-pages-about-ben-tsx" */),
  "component---src-pages-pages-about-tsx": () => import("./../../../src/pages/pages/about.tsx" /* webpackChunkName: "component---src-pages-pages-about-tsx" */),
  "component---src-pages-pages-antique-rugs-tsx": () => import("./../../../src/pages/pages/antique-rugs.tsx" /* webpackChunkName: "component---src-pages-pages-antique-rugs-tsx" */),
  "component---src-pages-pages-antiques-showroom-tsx": () => import("./../../../src/pages/pages/antiques-showroom.tsx" /* webpackChunkName: "component---src-pages-pages-antiques-showroom-tsx" */),
  "component---src-pages-pages-bespoke-services-tsx": () => import("./../../../src/pages/pages/bespoke-services.tsx" /* webpackChunkName: "component---src-pages-pages-bespoke-services-tsx" */),
  "component---src-pages-pages-brochure-tsx": () => import("./../../../src/pages/pages/brochure.tsx" /* webpackChunkName: "component---src-pages-pages-brochure-tsx" */),
  "component---src-pages-pages-catalog-tsx": () => import("./../../../src/pages/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-pages-catalog-tsx" */),
  "component---src-pages-pages-contact-tsx": () => import("./../../../src/pages/pages/contact.tsx" /* webpackChunkName: "component---src-pages-pages-contact-tsx" */),
  "component---src-pages-pages-contract-design-studio-tsx": () => import("./../../../src/pages/pages/contract-design-studio.tsx" /* webpackChunkName: "component---src-pages-pages-contract-design-studio-tsx" */),
  "component---src-pages-pages-custom-rug-studio-tsx": () => import("./../../../src/pages/pages/custom-rug-studio.tsx" /* webpackChunkName: "component---src-pages-pages-custom-rug-studio-tsx" */),
  "component---src-pages-pages-design-services-tsx": () => import("./../../../src/pages/pages/design-services.tsx" /* webpackChunkName: "component---src-pages-pages-design-services-tsx" */),
  "component---src-pages-pages-digitalsourcebooks-tsx": () => import("./../../../src/pages/pages/digitalsourcebooks.tsx" /* webpackChunkName: "component---src-pages-pages-digitalsourcebooks-tsx" */),
  "component---src-pages-pages-faqs-tsx": () => import("./../../../src/pages/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-pages-faqs-tsx" */),
  "component---src-pages-pages-first-order-sale-tsx": () => import("./../../../src/pages/pages/first-order-sale.tsx" /* webpackChunkName: "component---src-pages-pages-first-order-sale-tsx" */),
  "component---src-pages-pages-newsletter-tsx": () => import("./../../../src/pages/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-pages-newsletter-tsx" */),
  "component---src-pages-pages-nyc-showroom-tsx": () => import("./../../../src/pages/pages/nyc-showroom.tsx" /* webpackChunkName: "component---src-pages-pages-nyc-showroom-tsx" */),
  "component---src-pages-pages-productsourcebook-tsx": () => import("./../../../src/pages/pages/productsourcebook.tsx" /* webpackChunkName: "component---src-pages-pages-productsourcebook-tsx" */),
  "component---src-pages-pages-rug-finder-tsx": () => import("./../../../src/pages/pages/rug-finder.tsx" /* webpackChunkName: "component---src-pages-pages-rug-finder-tsx" */),
  "component---src-pages-pages-rugsourcebook-tsx": () => import("./../../../src/pages/pages/rugsourcebook.tsx" /* webpackChunkName: "component---src-pages-pages-rugsourcebook-tsx" */),
  "component---src-pages-pages-sale-tsx": () => import("./../../../src/pages/pages/sale.tsx" /* webpackChunkName: "component---src-pages-pages-sale-tsx" */),
  "component---src-pages-pages-showroom-tsx": () => import("./../../../src/pages/pages/showroom.tsx" /* webpackChunkName: "component---src-pages-pages-showroom-tsx" */),
  "component---src-pages-pages-size-guide-tsx": () => import("./../../../src/pages/pages/size-guide.tsx" /* webpackChunkName: "component---src-pages-pages-size-guide-tsx" */),
  "component---src-pages-pages-spring-catalogue-tsx": () => import("./../../../src/pages/pages/spring-catalogue.tsx" /* webpackChunkName: "component---src-pages-pages-spring-catalogue-tsx" */),
  "component---src-pages-pages-summer-catalogue-tsx": () => import("./../../../src/pages/pages/summer-catalogue.tsx" /* webpackChunkName: "component---src-pages-pages-summer-catalogue-tsx" */),
  "component---src-pages-pages-trade-catalogue-tsx": () => import("./../../../src/pages/pages/trade-catalogue.tsx" /* webpackChunkName: "component---src-pages-pages-trade-catalogue-tsx" */),
  "component---src-pages-pages-trade-program-tsx": () => import("./../../../src/pages/pages/trade-program.tsx" /* webpackChunkName: "component---src-pages-pages-trade-program-tsx" */),
  "component---src-pages-pages-try-in-home-tsx": () => import("./../../../src/pages/pages/try-in-home.tsx" /* webpackChunkName: "component---src-pages-pages-try-in-home-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-pages-shop-by-room-tsx": () => import("./../../../src/pages/shop-by-room.tsx" /* webpackChunkName: "component---src-pages-shop-by-room-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-templates-blog-blog-article-tsx": () => import("./../../../src/templates/blog/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-blog-article-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-collection-collection-antique-rugs-tsx": () => import("./../../../src/templates/collection/collection-antique-rugs.tsx" /* webpackChunkName: "component---src-templates-collection-collection-antique-rugs-tsx" */),
  "component---src-templates-collection-collection-art-tsx": () => import("./../../../src/templates/collection/collection-art.tsx" /* webpackChunkName: "component---src-templates-collection-collection-art-tsx" */),
  "component---src-templates-collection-collection-collaboration-tsx": () => import("./../../../src/templates/collection/collection-collaboration.tsx" /* webpackChunkName: "component---src-templates-collection-collection-collaboration-tsx" */),
  "component---src-templates-collection-collection-fabric-swatches-tsx": () => import("./../../../src/templates/collection/collection-fabric-swatches.tsx" /* webpackChunkName: "component---src-templates-collection-collection-fabric-swatches-tsx" */),
  "component---src-templates-collection-collection-featured-tsx": () => import("./../../../src/templates/collection/collection-featured.tsx" /* webpackChunkName: "component---src-templates-collection-collection-featured-tsx" */),
  "component---src-templates-collection-collection-furniture-tsx": () => import("./../../../src/templates/collection/collection-furniture.tsx" /* webpackChunkName: "component---src-templates-collection-collection-furniture-tsx" */),
  "component---src-templates-collection-collection-group-tsx": () => import("./../../../src/templates/collection/collection-group.tsx" /* webpackChunkName: "component---src-templates-collection-collection-group-tsx" */),
  "component---src-templates-collection-collection-last-chance-tsx": () => import("./../../../src/templates/collection/collection-lastChance.tsx" /* webpackChunkName: "component---src-templates-collection-collection-last-chance-tsx" */),
  "component---src-templates-collection-collection-lifestyle-tsx": () => import("./../../../src/templates/collection/collection-lifestyle.tsx" /* webpackChunkName: "component---src-templates-collection-collection-lifestyle-tsx" */),
  "component---src-templates-collection-collection-rugs-stacked-tsx": () => import("./../../../src/templates/collection/collection-rugs-stacked.tsx" /* webpackChunkName: "component---src-templates-collection-collection-rugs-stacked-tsx" */),
  "component---src-templates-collection-collection-rugs-tsx": () => import("./../../../src/templates/collection/collection-rugs.tsx" /* webpackChunkName: "component---src-templates-collection-collection-rugs-tsx" */),
  "component---src-templates-collection-index-tsx": () => import("./../../../src/templates/collection/index.tsx" /* webpackChunkName: "component---src-templates-collection-index-tsx" */),
  "component---src-templates-new-homepage-tsx": () => import("./../../../src/templates/newHomepage.tsx" /* webpackChunkName: "component---src-templates-new-homepage-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-page-wide-tsx": () => import("./../../../src/templates/page-wide.tsx" /* webpackChunkName: "component---src-templates-page-wide-tsx" */),
  "component---src-templates-product-index-tsx": () => import("./../../../src/templates/product/index.tsx" /* webpackChunkName: "component---src-templates-product-index-tsx" */),
  "component---src-templates-product-product-gift-card-tsx": () => import("./../../../src/templates/product/product-gift-card.tsx" /* webpackChunkName: "component---src-templates-product-product-gift-card-tsx" */),
  "component---src-templates-room-tsx": () => import("./../../../src/templates/room.tsx" /* webpackChunkName: "component---src-templates-room-tsx" */)
}

